*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: sweet-sans-pro;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

:root {
  --screen-width: 100vw;
  --current-screen: 100vw;
  --container-padding: 1rem;
}

@media (width >= 640px) {
  :root {
    --current-screen: 640px;
  }
}

@media (width >= 768px) {
  :root {
    --current-screen: 768px;
  }
}

@media (width >= 1024px) {
  :root {
    --current-screen: 1024px;
  }
}

@media (width >= 1280px) {
  :root {
    --current-screen: 1280px;
  }
}

@media (width >= 1536px) {
  :root {
    --current-screen: 1536px;
  }
}

@media (height >= 800px) {
  :root --current-screen {
    raw: (min-height: 800px);
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: #494c61;
  max-width: 60ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.dark .prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 2.25rem;
  font-weight: 700;
  font-size: [object Object];
  letter-spacing: .05em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-family: tribute;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: .875rem;
  font-weight: 600;
  font-size: [object Object];
  text-transform: uppercase;
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-family: termina;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose :where(h1, h2, h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #6d4c3e;
  font-weight: 400;
}

.prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
}

.dark .prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.77778;
}

.prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.09091em;
  margin-bottom: 1.09091em;
  font-size: 1.22222em;
  line-height: 1.45455;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-left: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .833333em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.86667em;
  margin-bottom: 1.06667em;
  font-size: 1.66667em;
  line-height: 1.33333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  font-size: 1.33333em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: .444444em;
  line-height: 1.55556;
}

.prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .3125rem;
  padding: .222222em .444444em;
  font-size: .888889em;
}

.prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .866667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 1.5em;
  font-size: .888889em;
  line-height: 1.75;
}

.prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(.prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(.prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  padding-left: 1.55556em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3.11111em;
  margin-bottom: 3.11111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .75em;
  padding-left: .75em;
  padding-right: .75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(.prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

:where(.prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 2.25rem;
  font-size: [object Object];
  margin-top: 0;
  font-family: termina;
}

.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.prose-2xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.prose-2xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.prose-2xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-2xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.prose-2xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.prose-2xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.prose-2xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  padding: .25em .333333em;
  font-size: .833333em;
}

.prose-2xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
}

.prose-2xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-2xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-2xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.prose-2xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose-2xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(.prose-2xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-2xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.58333em;
}

.prose-2xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose-2xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.prose-2xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.prose-2xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .8em .6em;
}

.prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.prose-2xl :where(.prose-2xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(.prose-2xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-8 {
  inset: 2rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-left-0 {
  left: 0;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1\/2 {
  left: -50%;
}

.-left-24 {
  left: -6rem;
}

.-left-\[240px\] {
  left: -240px;
}

.-right-12 {
  right: -3rem;
}

.-right-\[15\%\] {
  right: -15%;
}

.-top-24 {
  top: -6rem;
}

.-top-32 {
  top: -8rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-52 {
  top: -13rem;
}

.-top-\[5\%\] {
  top: -5%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-32 {
  top: 8rem;
}

.top-\[-400px\] {
  top: -400px;
}

.top-\[45\%\] {
  top: 45%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[50\] {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[999999\] {
  z-index: 999999;
}

.z-\[9999\] {
  z-index: 9999;
}

.z-\[999\] {
  z-index: 999;
}

.z-\[99\] {
  z-index: 99;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-\[110\] {
  margin-top: -110px;
  margin-bottom: -110px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-\[5px\] {
  margin-top: -5px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-photo {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[25\] {
  height: 25px;
}

.h-\[300\] {
  height: 300px;
}

.h-\[345px\] {
  height: 345px;
}

.h-\[475px\] {
  height: 475px;
}

.h-\[90px\] {
  height: 90px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[175px\] {
  max-height: 175px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[600\] {
  max-height: 600px;
}

.max-h-\[650px\] {
  max-height: 650px;
}

.max-h-\[750px\] {
  max-height: 750px;
}

.min-h-1\/2screen {
  min-height: 50vh;
}

.min-h-\[1em\] {
  min-height: 1em;
}

.min-h-\[420\] {
  min-height: 420px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[25\] {
  width: 25px;
}

.w-\[400\] {
  width: 400px;
}

.w-\[46\%\] {
  width: 46%;
}

.w-\[475px\] {
  width: 475px;
}

.w-\[90px\] {
  width: 90px;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[115px\] {
  max-width: 115px;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[215px\] {
  max-width: 215px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[248px\] {
  max-width: 248px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[330px\] {
  max-width: 330px;
}

.max-w-\[540\] {
  max-width: 540px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[30\%\] {
  --tw-translate-y: -30%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[300\%\] {
  --tw-translate-y: -300%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-96 {
  --tw-translate-y: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[400px\] {
  --tw-translate-y: 400px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-270 {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.35\] {
  --tw-scale-x: .35;
  --tw-scale-y: .35;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[2\] {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[3\.0\] {
  --tw-scale-x: 3;
  --tw-scale-y: 3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-background-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(28 31 57 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

.place-self-center {
  place-self: center;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#7F80A9\]\/60 {
  border-color: #7f80a999;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.border-background-200 {
  --tw-border-opacity: 1;
  border-color: rgb(164 165 176 / var(--tw-border-opacity));
}

.border-background-400 {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: rgb(190 190 198 / var(--tw-border-opacity));
}

.border-foreground-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 232 / var(--tw-border-opacity));
}

.border-foreground-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 216 221 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#7F80A9\]\/60 {
  background-color: #7f80a999;
}

.bg-accent, .bg-accent-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.bg-background-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

.bg-background-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

.bg-background\/50 {
  background-color: #1c1f3980;
}

.bg-background\/80 {
  background-color: #1c1f39cc;
}

.bg-background\/90 {
  background-color: #1c1f39e6;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgb(82 179 175 / var(--tw-bg-opacity));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.bg-foreground-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 216 221 / var(--tw-bg-opacity));
}

.bg-foreground-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(114 114 119 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-magenta {
  --tw-bg-opacity: 1;
  background-color: rgb(183 49 99 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(244 164 64 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[image\:var\(--image-url\)\] {
  background-image: var(--image-url);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-accent-600\/90 {
  --tw-gradient-from: #916653e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #91665300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-background-600\/80 {
  --tw-gradient-from: #16192ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #16192e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-accent\/75 {
  --tw-gradient-to: #b57f68bf var(--tw-gradient-to-position);
}

.to-background-600\/40 {
  --tw-gradient-to: #16192e66 var(--tw-gradient-to-position);
}

.to-foreground-100 {
  --tw-gradient-to: #f2f2f4 var(--tw-gradient-to-position);
}

.bg-\[cover\] {
  background-size: cover;
}

.bg-\[length\:100vw_auto\] {
  background-size: 100vw;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[center_top_1rem\] {
  background-position: 50% 1rem;
}

.bg-\[center_top_2rem\] {
  background-position: 50% 2rem;
}

.bg-\[center_top_4rem\] {
  background-position: 50% 4rem;
}

.bg-bottom {
  background-position: bottom;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-accent {
  fill: #b57f68;
}

.fill-background {
  fill: #1c1f39;
}

.stroke-black {
  stroke: #000;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-left-top {
  object-position: left top;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-display {
  font-family: termina;
}

.font-mono {
  font-family: monospace;
}

.font-sans {
  font-family: sweet-sans-pro;
}

.font-serif {
  font-family: tribute;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.not-italic {
  font-style: normal;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-\[\.10rem\] {
  letter-spacing: .1rem;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-accent-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(181 127 104 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-100 {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.text-accent-500 {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-700 {
  --tw-text-opacity: 1;
  color: rgb(109 76 62 / var(--tw-text-opacity));
}

.text-accent-800 {
  --tw-text-opacity: 1;
  color: rgb(72 51 42 / var(--tw-text-opacity));
}

.text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-400 {
  --tw-text-opacity: 1;
  color: rgb(73 76 97 / var(--tw-text-opacity));
}

.text-background-500 {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-900 {
  --tw-text-opacity: 1;
  color: rgb(6 6 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-cyan {
  --tw-text-opacity: 1;
  color: rgb(82 179 175 / var(--tw-text-opacity));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-100 {
  --tw-text-opacity: 1;
  color: rgb(242 242 244 / var(--tw-text-opacity));
}

.text-foreground-300 {
  --tw-text-opacity: 1;
  color: rgb(216 216 221 / var(--tw-text-opacity));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-500\/50 {
  color: #bebec680;
}

.text-foreground-700 {
  --tw-text-opacity: 1;
  color: rgb(114 114 119 / var(--tw-text-opacity));
}

.text-foreground-800 {
  --tw-text-opacity: 1;
  color: rgb(76 76 79 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-magenta {
  --tw-text-opacity: 1;
  color: rgb(183 49 99 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(244 164 64 / var(--tw-text-opacity));
}

.text-yellow\/50 {
  color: #f4a44080;
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-background-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(210 210 215 / var(--tw-placeholder-opacity));
}

.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 226 226 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-background-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 165 176 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-0 {
  transition-delay: 0s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-\[200ms\] {
  transition-delay: .2s;
}

.delay-\[400ms\] {
  transition-delay: .4s;
}

.delay-\[4s\] {
  transition-delay: 4s;
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-\[1s\] {
  transition-duration: 1s;
}

.duration-\[3s\] {
  transition-duration: 3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.fluid-base {
  font-size: 18px;
  line-height: var(--fluid-line-height);
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.4;
  --fluid-letter-spacing: .5px;
}

.fluid-lg {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .009375em;
  font-size: clamp(1.25rem, .9741vw + .9253rem, 1.618rem);
  line-height: var(--fluid-line-height);
}

.fluid-xl {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .0125em;
  font-size: clamp(1.5625rem, 2.7938vw + .6312rem, 2.6179rem);
  line-height: var(--fluid-line-height);
}

.container-bleed, .container-bleed.swiper {
  width: calc((var(--current-screen)  - var(--container-padding))  + (var(--screen-width)  - var(--current-screen)) / 2);
}

.prose p {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.prose p:not(:last-child) {
  margin-bottom: 1rem;
}

#headlessui-portal-root {
  z-index: 100;
  position: relative;
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.btn {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .1em;
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  align-items: center;
  padding: .5rem 1rem .375rem;
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: none;
  transition-duration: .2s;
  display: inline-flex;
}

.btn:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #1c1f39;
}

.btn:hover:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
}

:is(.dark .btn:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
  --tw-ring-offset-color: #1c1f39;
}

.btn.longtext {
  text-transform: none;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: .375rem 1rem .5rem;
  font-family: sweet-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.btn.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 232 / var(--tw-bg-opacity));
}

.btn.btn-primary:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
  --tw-ring-offset-color: #1c1f39;
}

.btn.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.btn.btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.btn.btn-secondary:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(164 165 176 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 31 57 / var(--tw-ring-opacity));
  --tw-ring-offset-color: #111322;
}

:is(.dark .btn.btn-secondary) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

:is(.dark .btn.btn-secondary:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .btn.btn-secondary:hover:active) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.btn.btn-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(6 6 11 / var(--tw-text-opacity));
}

.btn.btn-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(145 102 83 / var(--tw-bg-opacity));
}

.btn.btn-accent:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
  --tw-ring-offset-color: #1c1f39;
}

.fluid-base {
  --fluid-line-height: 1.6;
}

.floater-element:hover .hover-image {
  opacity: 1;
}

* {
  box-sizing: border-box;
}

:root {
  --button-space: 2rem;
}

.stars {
  background-image: url("stars.63d26055.webp");
  background-size: 1000px;
}

.cls-1 {
  fill: #b6806b;
}

.cls-2 {
  fill: #bebec6;
}

.sweet-sans, body, .font-sans {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sweet-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.sweet-sans-bold, strong, b {
  font-family: sweet-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 800;
}

.tribute, em, i, .font-serif {
  font-family: tribute, serif;
  font-style: italic;
  font-weight: 400;
}

.termina, .font-display {
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 400;
}

p em {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
  font-size: 1.5rem;
  line-height: 2rem;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #b6806b;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #b6806b;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
}

.panel {
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  display: flex;
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

@media (width >= 1024px) {
  .lg\:container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (width >= 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }

  .lg\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .lg\:prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .lg\:prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .lg\:prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .lg\:prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .lg\:prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .lg\:prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .222222em .444444em;
    font-size: .888889em;
  }

  .lg\:prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .lg\:prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .lg\:prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .lg\:prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .lg\:prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .lg\:prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .lg\:prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .lg\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .75em;
  }

  .lg\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  :where(.lg\:prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 2.25rem;
    font-size: [object Object];
    margin-top: 0;
    font-family: termina;
  }
}

@media (width >= 1280px) {
  .xl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .xl\:prose-xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .xl\:prose-xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.06667em;
  }

  .xl\:prose-xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .xl\:prose-xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .xl\:prose-xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.25rem;
    font-size: [object Object];
    margin-top: 1.6em;
    margin-bottom: .666667em;
    line-height: 1.33333;
  }

  .xl\:prose-xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .xl\:prose-xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .25em .4em;
    font-size: .9em;
  }

  .xl\:prose-xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .xl\:prose-xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1.11111em 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .xl\:prose-xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .xl\:prose-xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .xl\:prose-xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .888889em;
    padding-left: .666667em;
    padding-right: .666667em;
  }

  .xl\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .888889em .666667em;
  }

  .xl\:prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-x-1\/2:before {
  content: var(--tw-content);
  left: 50%;
  right: 50%;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:h-3\/4:before {
  content: var(--tw-content);
  height: 75%;
}

.before\:h-\[2px\]:before {
  content: var(--tw-content);
  height: 2px;
}

.before\:h-\[425\]:before {
  content: var(--tw-content);
  height: 425px;
}

.before\:w-3\/4:before {
  content: var(--tw-content);
  width: 75%;
}

.before\:w-\[200px\]:before {
  content: var(--tw-content);
  width: 200px;
}

.before\:w-\[425\]:before {
  content: var(--tw-content);
  width: 425px;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-full:before {
  content: var(--tw-content);
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.before\:bg-\[image\:var\(--image-url\)\]:before {
  content: var(--tw-content);
  background-image: var(--image-url);
}

.before\:bg-contain:before {
  content: var(--tw-content);
  background-size: contain;
}

.before\:bg-no-repeat:before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:opacity-80:before {
  content: var(--tw-content);
  opacity: .8;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-accent:hover {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.hover\:bg-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.hover\:bg-accent-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(145 102 83 / var(--tw-bg-opacity));
}

.hover\:bg-background-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.hover\:bg-background-400\/50:hover {
  background-color: #494c6180;
}

.hover\:bg-foreground-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 232 / var(--tw-bg-opacity));
}

.hover\:text-accent:hover {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.hover\:text-accent-100:hover {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.hover\:text-accent-900:hover {
  --tw-text-opacity: 1;
  color: rgb(36 25 21 / var(--tw-text-opacity));
}

.hover\:text-background:hover {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.hover\:text-foreground-500:hover {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:ring-4:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-foreground\/80:hover {
  --tw-ring-color: #bebec6cc;
}

.hover\:ring-offset-4:hover {
  --tw-ring-offset-width: 4px;
}

.hover\:ring-offset-\[\#303156\]:hover {
  --tw-ring-offset-color: #303156;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-accent:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.focus\:ring-accent-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 204 195 / var(--tw-ring-opacity));
}

.focus\:ring-foreground:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-background:focus {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:bg-accent:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-200:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(164 165 176 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-400\/25:hover:active {
  background-color: #494c6140;
}

.active\:hover\:bg-foreground-100:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.active\:hover\:ring-2:hover:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:hover\:ring-accent:hover:active, .active\:hover\:ring-accent-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.active\:hover\:ring-background-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 31 57 / var(--tw-ring-opacity));
}

.active\:hover\:ring-foreground:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.active\:hover\:ring-offset-2:hover:active {
  --tw-ring-offset-width: 2px;
}

.active\:hover\:ring-offset-background:hover:active, .active\:hover\:ring-offset-background-500:hover:active {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:ring-offset-background-700:hover:active {
  --tw-ring-offset-color: #111322;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-25:disabled {
  opacity: .25;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group\/item:hover .group-hover\/item\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.group:hover .group-hover\:stroke-accent {
  stroke: #b57f68;
}

.group\/cs-link:hover .group-hover\/cs-link\:text-accent-500, .group\/item:hover .group-hover\/item\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group:hover .group-hover\:mix-blend-normal {
  mix-blend-mode: normal;
}

.group:hover .group-hover\:grayscale-0 {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:border-background-400) {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-background-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(11 12 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-background-100) {
  --tw-text-opacity: 1;
  color: rgb(210 210 215 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-foreground), :is(.dark .dark\:text-foreground-500) {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-400) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-background-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(73 76 97 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:opacity-50) {
  opacity: .5;
}

:is(.dark .dark\:ring-background-400) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-red-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:hover\:bg-background-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:opacity-75:hover) {
  opacity: .75;
}

:is(.dark .dark\:focus\:ring-accent-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(145 102 83 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-background-400:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-offset-background:focus) {
  --tw-ring-offset-color: #1c1f39;
}

:is(.dark .dark\:focus\:ring-offset-background-700:focus) {
  --tw-ring-offset-color: #111322;
}

:is(.dark .dark\:active\:opacity-100:active) {
  opacity: 1;
}

:is(.dark .dark\:active\:hover\:bg-background-500:hover:active) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm\:-top-8 {
    top: -2rem;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:-mt-0 {
    margin-top: 0;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:max-w-60 {
    max-width: 15rem;
  }

  .sm\:max-w-80 {
    max-width: 20rem;
  }

  .sm\:max-w-\[140px\] {
    max-width: 140px;
  }

  .sm\:max-w-\[155px\] {
    max-width: 155px;
  }

  .sm\:max-w-\[560px\] {
    max-width: 560px;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:tracking-\[\.18rem\] {
    letter-spacing: .18rem;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md\:-right-12 {
    right: -3rem;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:right-0, .md\:right-\[0\] {
    right: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[-250px\] {
    top: -250px;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-auto {
    aspect-ratio: auto;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:max-h-\[300px\] {
    max-height: 300px;
  }

  .md\:min-h-\[400\] {
    min-height: 400px;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:max-w-\[450px\] {
    max-width: 450px;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:bg-background {
    --tw-bg-opacity: 1;
    background-color: rgb(28 31 57 / var(--tw-bg-opacity));
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-\[image\:var\(--image-url\)\] {
    background-image: var(--image-url);
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-foreground {
    --tw-text-opacity: 1;
    color: rgb(190 190 198 / var(--tw-text-opacity));
  }

  .md\:text-foreground-100 {
    --tw-text-opacity: 1;
    color: rgb(242 242 244 / var(--tw-text-opacity));
  }

  .md\:ui-p-right {
    padding-right: var(--button-space);
  }

  .md\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }

  .md\:before\:h-\[425\]:before {
    content: var(--tw-content);
    height: 425px;
  }

  .md\:before\:w-\[425\]:before {
    content: var(--tw-content);
    width: 425px;
  }
}

@media (width >= 1024px) {
  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:right-\[-100px\] {
    right: -100px;
  }

  .lg\:top-\[-150px\] {
    top: -150px;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:min-h-\[420\] {
    min-height: 420px;
  }

  .lg\:min-h-\[600\] {
    min-height: 600px;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-\[330px\] {
    max-width: 330px;
  }

  .lg\:max-w-\[400px\] {
    max-width: 400px;
  }

  .lg\:max-w-\[440px\] {
    max-width: 440px;
  }

  .lg\:max-w-\[760px\] {
    max-width: 760px;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:translate-x-\[10\%\] {
    --tw-translate-x: 10%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:container-bleed, .lg\:container-bleed.swiper {
    width: calc((var(--current-screen)  - var(--container-padding))  + (var(--screen-width)  - var(--current-screen)) / 2);
  }
}

@media (width >= 1280px) {
  .xl\:right-\[-150px\] {
    right: -150px;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:min-h-\[520\] {
    min-height: 520px;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-36 {
    gap: 9rem;
  }

  .xl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:leading-\[5rem\] {
    line-height: 5rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:hidden {
    display: none;
  }

  .\32 xl\:max-h-\[600px\] {
    max-height: 600px;
  }

  .\32 xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (height >= 800px) {
  .height-sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .height-sm\:pt-\[5vh\] {
    padding-top: 5vh;
  }
}

.\[\&\>\*\:not\(\:last-child\)\]\:my-2 > :not(:last-child) {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (width >= 768px) {
  .md\:\[\&\>\*\:not\(\:last-child\)\]\:my-4 > :not(:last-child) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.\[\&\>div\>div\>input\]\:py-3 > div > div > input {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>div\>div\>input\]\:placeholder-background-300 > div > div > input::placeholder, .\[\&\>div\>div\>textarea\]\:placeholder-background-300 > div > div > textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(119 121 136 / var(--tw-placeholder-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>div\>textarea\]\:lg\:h-56 > div > div > textarea {
    height: 14rem;
  }
}

.\[\&\>div\>div\]\:justify-between > div > div {
  justify-content: space-between;
}

.\[\&\>div\>label\]\:flex-shrink-0 > div > label {
  flex-shrink: 0;
}

.\[\&\>div\>label\]\:text-lg > div > label {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\[\&\>div\>label\]\:text-slate-900 > div > label {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>label\]\:lg\:w-1\/2 > div > label {
    width: 50%;
  }
}

.\[\&\>div\>p\]\:text-white > div > p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&\>div\]\:mx-auto > div {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>div\]\:max-w-5xl > div {
  max-width: 64rem;
}

.\[\&\>div\]\:items-center > div {
  align-items: center;
}

@media (width >= 768px) {
  .md\:\[\&\>div\]\:justify-start > div {
    justify-content: flex-start;
  }
}

@media (width >= 1024px) {
  .\[\&\>div\]\:lg\:flex > div {
    display: flex;
  }
}

@media (width >= 1280px) {
  .\[\&\>div\]\:xl\:gap-6 > div {
    gap: 1.5rem;
  }
}

.\[\&\>img\]\:duration-500 > img {
  transition-duration: .5s;
}

.\[\&\>p\:not\(\:last-child\)\]\:mb-6 > p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.\[\&\>p\>a\]\:text-accent > p > a {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.\[\&\>p\]\:mx-auto > p {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>p\]\:mb-4 > p {
  margin-bottom: 1rem;
}

.\[\&\>p\]\:mb-6 > p {
  margin-bottom: 1.5rem;
}

.\[\&\>p\]\:mt-0 > p {
  margin-top: 0;
}

.\[\&\>p\]\:max-w-xl > p {
  max-width: 36rem;
}

.\[\&\>p\]\:text-xl > p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  .lg\:\[\&\>p\]\:leading-8 > p {
    line-height: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:\[\&\>p\]\:leading-10 > p {
    line-height: 2.5rem;
  }
}

.\[\&\>span\]\:px-2 > span {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&\>span\]\:py-1 > span {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.\[\&\>ul\]\:text-xl > ul {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: sweet-sans-pro;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

:root {
  --screen-width: 100vw;
  --current-screen: 100vw;
  --container-padding: 1rem;
}

@media (width >= 640px) {
  :root {
    --current-screen: 640px;
  }
}

@media (width >= 768px) {
  :root {
    --current-screen: 768px;
  }
}

@media (width >= 1024px) {
  :root {
    --current-screen: 1024px;
  }
}

@media (width >= 1280px) {
  :root {
    --current-screen: 1280px;
  }
}

@media (width >= 1536px) {
  :root {
    --current-screen: 1536px;
  }
}

@media (height >= 800px) {
  :root --current-screen {
    raw: (min-height: 800px);
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: #494c61;
  max-width: 60ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.dark .prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 2.25rem;
  font-weight: 700;
  font-size: [object Object];
  letter-spacing: .05em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-family: tribute;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: .875rem;
  font-weight: 600;
  font-size: [object Object];
  text-transform: uppercase;
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-family: termina;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose :where(h1, h2, h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #6d4c3e;
  font-weight: 400;
}

.prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
}

.dark .prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.77778;
}

.prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.09091em;
  margin-bottom: 1.09091em;
  font-size: 1.22222em;
  line-height: 1.45455;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-left: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .833333em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.86667em;
  margin-bottom: 1.06667em;
  font-size: 1.66667em;
  line-height: 1.33333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  font-size: 1.33333em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: .444444em;
  line-height: 1.55556;
}

.prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .3125rem;
  padding: .222222em .444444em;
  font-size: .888889em;
}

.prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .866667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 1.5em;
  font-size: .888889em;
  line-height: 1.75;
}

.prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(.prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(.prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  padding-left: 1.55556em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3.11111em;
  margin-bottom: 3.11111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .75em;
  padding-left: .75em;
  padding-right: .75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(.prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

:where(.prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 2.25rem;
  font-size: [object Object];
  margin-top: 0;
  font-family: termina;
}

.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.prose-2xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.prose-2xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.prose-2xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-2xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.prose-2xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.prose-2xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.prose-2xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  padding: .25em .333333em;
  font-size: .833333em;
}

.prose-2xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
}

.prose-2xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-2xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-2xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.prose-2xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose-2xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(.prose-2xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-2xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.58333em;
}

.prose-2xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose-2xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.prose-2xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.prose-2xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .8em .6em;
}

.prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.prose-2xl :where(.prose-2xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(.prose-2xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-8 {
  inset: 2rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-left-0 {
  left: 0;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1\/2 {
  left: -50%;
}

.-left-24 {
  left: -6rem;
}

.-left-\[240px\] {
  left: -240px;
}

.-right-12 {
  right: -3rem;
}

.-right-\[15\%\] {
  right: -15%;
}

.-top-24 {
  top: -6rem;
}

.-top-32 {
  top: -8rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-52 {
  top: -13rem;
}

.-top-\[5\%\] {
  top: -5%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-32 {
  top: 8rem;
}

.top-\[-400px\] {
  top: -400px;
}

.top-\[45\%\] {
  top: 45%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[50\] {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[999999\] {
  z-index: 999999;
}

.z-\[9999\] {
  z-index: 9999;
}

.z-\[999\] {
  z-index: 999;
}

.z-\[99\] {
  z-index: 99;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-\[110\] {
  margin-top: -110px;
  margin-bottom: -110px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-\[5px\] {
  margin-top: -5px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-photo {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[25\] {
  height: 25px;
}

.h-\[300\] {
  height: 300px;
}

.h-\[345px\] {
  height: 345px;
}

.h-\[475px\] {
  height: 475px;
}

.h-\[90px\] {
  height: 90px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[175px\] {
  max-height: 175px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[600\] {
  max-height: 600px;
}

.max-h-\[650px\] {
  max-height: 650px;
}

.max-h-\[750px\] {
  max-height: 750px;
}

.min-h-1\/2screen {
  min-height: 50vh;
}

.min-h-\[1em\] {
  min-height: 1em;
}

.min-h-\[420\] {
  min-height: 420px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[25\] {
  width: 25px;
}

.w-\[400\] {
  width: 400px;
}

.w-\[46\%\] {
  width: 46%;
}

.w-\[475px\] {
  width: 475px;
}

.w-\[90px\] {
  width: 90px;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[115px\] {
  max-width: 115px;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[215px\] {
  max-width: 215px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[248px\] {
  max-width: 248px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[330px\] {
  max-width: 330px;
}

.max-w-\[540\] {
  max-width: 540px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[30\%\] {
  --tw-translate-y: -30%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[300\%\] {
  --tw-translate-y: -300%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-96 {
  --tw-translate-y: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[400px\] {
  --tw-translate-y: 400px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-270 {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.35\] {
  --tw-scale-x: .35;
  --tw-scale-y: .35;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[2\] {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[3\.0\] {
  --tw-scale-x: 3;
  --tw-scale-y: 3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-background-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(28 31 57 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

.place-self-center {
  place-self: center;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#7F80A9\]\/60 {
  border-color: #7f80a999;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.border-background-200 {
  --tw-border-opacity: 1;
  border-color: rgb(164 165 176 / var(--tw-border-opacity));
}

.border-background-400 {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: rgb(190 190 198 / var(--tw-border-opacity));
}

.border-foreground-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 232 / var(--tw-border-opacity));
}

.border-foreground-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 216 221 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#7F80A9\]\/60 {
  background-color: #7f80a999;
}

.bg-accent, .bg-accent-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.bg-background-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

.bg-background-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

.bg-background\/50 {
  background-color: #1c1f3980;
}

.bg-background\/80 {
  background-color: #1c1f39cc;
}

.bg-background\/90 {
  background-color: #1c1f39e6;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgb(82 179 175 / var(--tw-bg-opacity));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.bg-foreground-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 216 221 / var(--tw-bg-opacity));
}

.bg-foreground-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(114 114 119 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-magenta {
  --tw-bg-opacity: 1;
  background-color: rgb(183 49 99 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(244 164 64 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[image\:var\(--image-url\)\] {
  background-image: var(--image-url);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-accent-600\/90 {
  --tw-gradient-from: #916653e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #91665300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-background-600\/80 {
  --tw-gradient-from: #16192ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #16192e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-accent\/75 {
  --tw-gradient-to: #b57f68bf var(--tw-gradient-to-position);
}

.to-background-600\/40 {
  --tw-gradient-to: #16192e66 var(--tw-gradient-to-position);
}

.to-foreground-100 {
  --tw-gradient-to: #f2f2f4 var(--tw-gradient-to-position);
}

.bg-\[cover\] {
  background-size: cover;
}

.bg-\[length\:100vw_auto\] {
  background-size: 100vw;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[center_top_1rem\] {
  background-position: 50% 1rem;
}

.bg-\[center_top_2rem\] {
  background-position: 50% 2rem;
}

.bg-\[center_top_4rem\] {
  background-position: 50% 4rem;
}

.bg-bottom {
  background-position: bottom;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-accent {
  fill: #b57f68;
}

.fill-background {
  fill: #1c1f39;
}

.stroke-black {
  stroke: #000;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-left-top {
  object-position: left top;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-display {
  font-family: termina;
}

.font-mono {
  font-family: monospace;
}

.font-sans {
  font-family: sweet-sans-pro;
}

.font-serif {
  font-family: tribute;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.not-italic {
  font-style: normal;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-\[\.10rem\] {
  letter-spacing: .1rem;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-accent-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(181 127 104 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-100 {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.text-accent-500 {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-700 {
  --tw-text-opacity: 1;
  color: rgb(109 76 62 / var(--tw-text-opacity));
}

.text-accent-800 {
  --tw-text-opacity: 1;
  color: rgb(72 51 42 / var(--tw-text-opacity));
}

.text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-400 {
  --tw-text-opacity: 1;
  color: rgb(73 76 97 / var(--tw-text-opacity));
}

.text-background-500 {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-900 {
  --tw-text-opacity: 1;
  color: rgb(6 6 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-cyan {
  --tw-text-opacity: 1;
  color: rgb(82 179 175 / var(--tw-text-opacity));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-100 {
  --tw-text-opacity: 1;
  color: rgb(242 242 244 / var(--tw-text-opacity));
}

.text-foreground-300 {
  --tw-text-opacity: 1;
  color: rgb(216 216 221 / var(--tw-text-opacity));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-500\/50 {
  color: #bebec680;
}

.text-foreground-700 {
  --tw-text-opacity: 1;
  color: rgb(114 114 119 / var(--tw-text-opacity));
}

.text-foreground-800 {
  --tw-text-opacity: 1;
  color: rgb(76 76 79 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-magenta {
  --tw-text-opacity: 1;
  color: rgb(183 49 99 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(244 164 64 / var(--tw-text-opacity));
}

.text-yellow\/50 {
  color: #f4a44080;
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-background-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(210 210 215 / var(--tw-placeholder-opacity));
}

.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 226 226 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-background-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 165 176 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-0 {
  transition-delay: 0s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-\[200ms\] {
  transition-delay: .2s;
}

.delay-\[400ms\] {
  transition-delay: .4s;
}

.delay-\[4s\] {
  transition-delay: 4s;
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-\[1s\] {
  transition-duration: 1s;
}

.duration-\[3s\] {
  transition-duration: 3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.fluid-base {
  font-size: 18px;
  line-height: var(--fluid-line-height);
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.4;
  --fluid-letter-spacing: .5px;
}

.fluid-lg {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .009375em;
  font-size: clamp(1.25rem, .9741vw + .9253rem, 1.618rem);
  line-height: var(--fluid-line-height);
}

.fluid-xl {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .0125em;
  font-size: clamp(1.5625rem, 2.7938vw + .6312rem, 2.6179rem);
  line-height: var(--fluid-line-height);
}

.intro h2, .intro h3 {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
  font-family: tribute;
  font-weight: 400;
}

.intro h2 {
  margin-top: 1.25rem;
  font-size: 3rem;
  line-height: 1;
}

.intro h3 {
  margin-top: 1rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

@media (width >= 1024px) {
  .lg\:container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (width >= 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }

  .lg\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .lg\:prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .lg\:prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .lg\:prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .lg\:prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .lg\:prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .lg\:prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .222222em .444444em;
    font-size: .888889em;
  }

  .lg\:prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .lg\:prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .lg\:prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .lg\:prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .lg\:prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .lg\:prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .lg\:prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .lg\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .75em;
  }

  .lg\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  :where(.lg\:prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 2.25rem;
    font-size: [object Object];
    margin-top: 0;
    font-family: termina;
  }
}

@media (width >= 1280px) {
  .xl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .xl\:prose-xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .xl\:prose-xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.06667em;
  }

  .xl\:prose-xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .xl\:prose-xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .xl\:prose-xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.25rem;
    font-size: [object Object];
    margin-top: 1.6em;
    margin-bottom: .666667em;
    line-height: 1.33333;
  }

  .xl\:prose-xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .xl\:prose-xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .25em .4em;
    font-size: .9em;
  }

  .xl\:prose-xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .xl\:prose-xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1.11111em 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .xl\:prose-xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .xl\:prose-xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .xl\:prose-xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .888889em;
    padding-left: .666667em;
    padding-right: .666667em;
  }

  .xl\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .888889em .666667em;
  }

  .xl\:prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-x-1\/2:before {
  content: var(--tw-content);
  left: 50%;
  right: 50%;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:h-3\/4:before {
  content: var(--tw-content);
  height: 75%;
}

.before\:h-\[2px\]:before {
  content: var(--tw-content);
  height: 2px;
}

.before\:h-\[425\]:before {
  content: var(--tw-content);
  height: 425px;
}

.before\:w-3\/4:before {
  content: var(--tw-content);
  width: 75%;
}

.before\:w-\[200px\]:before {
  content: var(--tw-content);
  width: 200px;
}

.before\:w-\[425\]:before {
  content: var(--tw-content);
  width: 425px;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-full:before {
  content: var(--tw-content);
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.before\:bg-\[image\:var\(--image-url\)\]:before {
  content: var(--tw-content);
  background-image: var(--image-url);
}

.before\:bg-contain:before {
  content: var(--tw-content);
  background-size: contain;
}

.before\:bg-no-repeat:before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:opacity-80:before {
  content: var(--tw-content);
  opacity: .8;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-accent:hover {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.hover\:bg-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.hover\:bg-accent-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(145 102 83 / var(--tw-bg-opacity));
}

.hover\:bg-background-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.hover\:bg-background-400\/50:hover {
  background-color: #494c6180;
}

.hover\:bg-foreground-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 232 / var(--tw-bg-opacity));
}

.hover\:text-accent:hover {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.hover\:text-accent-100:hover {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.hover\:text-accent-900:hover {
  --tw-text-opacity: 1;
  color: rgb(36 25 21 / var(--tw-text-opacity));
}

.hover\:text-background:hover {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.hover\:text-foreground-500:hover {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:ring-4:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-foreground\/80:hover {
  --tw-ring-color: #bebec6cc;
}

.hover\:ring-offset-4:hover {
  --tw-ring-offset-width: 4px;
}

.hover\:ring-offset-\[\#303156\]:hover {
  --tw-ring-offset-color: #303156;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-accent:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.focus\:ring-accent-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 204 195 / var(--tw-ring-opacity));
}

.focus\:ring-foreground:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-background:focus {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:bg-accent:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-200:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(164 165 176 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-400\/25:hover:active {
  background-color: #494c6140;
}

.active\:hover\:bg-foreground-100:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.active\:hover\:ring-2:hover:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:hover\:ring-accent:hover:active, .active\:hover\:ring-accent-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.active\:hover\:ring-background-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 31 57 / var(--tw-ring-opacity));
}

.active\:hover\:ring-foreground:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.active\:hover\:ring-offset-2:hover:active {
  --tw-ring-offset-width: 2px;
}

.active\:hover\:ring-offset-background:hover:active, .active\:hover\:ring-offset-background-500:hover:active {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:ring-offset-background-700:hover:active {
  --tw-ring-offset-color: #111322;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-25:disabled {
  opacity: .25;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group\/item:hover .group-hover\/item\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.group:hover .group-hover\:stroke-accent {
  stroke: #b57f68;
}

.group\/cs-link:hover .group-hover\/cs-link\:text-accent-500, .group\/item:hover .group-hover\/item\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group:hover .group-hover\:mix-blend-normal {
  mix-blend-mode: normal;
}

.group:hover .group-hover\:grayscale-0 {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:border-background-400) {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-background-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(11 12 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-background-100) {
  --tw-text-opacity: 1;
  color: rgb(210 210 215 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-foreground), :is(.dark .dark\:text-foreground-500) {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-400) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-background-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(73 76 97 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:opacity-50) {
  opacity: .5;
}

:is(.dark .dark\:ring-background-400) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-red-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:hover\:bg-background-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:opacity-75:hover) {
  opacity: .75;
}

:is(.dark .dark\:focus\:ring-accent-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(145 102 83 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-background-400:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-offset-background:focus) {
  --tw-ring-offset-color: #1c1f39;
}

:is(.dark .dark\:focus\:ring-offset-background-700:focus) {
  --tw-ring-offset-color: #111322;
}

:is(.dark .dark\:active\:opacity-100:active) {
  opacity: 1;
}

:is(.dark .dark\:active\:hover\:bg-background-500:hover:active) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm\:-top-8 {
    top: -2rem;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:-mt-0 {
    margin-top: 0;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:max-w-60 {
    max-width: 15rem;
  }

  .sm\:max-w-80 {
    max-width: 20rem;
  }

  .sm\:max-w-\[140px\] {
    max-width: 140px;
  }

  .sm\:max-w-\[155px\] {
    max-width: 155px;
  }

  .sm\:max-w-\[560px\] {
    max-width: 560px;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:tracking-\[\.18rem\] {
    letter-spacing: .18rem;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md\:-right-12 {
    right: -3rem;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:right-0, .md\:right-\[0\] {
    right: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[-250px\] {
    top: -250px;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-auto {
    aspect-ratio: auto;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:max-h-\[300px\] {
    max-height: 300px;
  }

  .md\:min-h-\[400\] {
    min-height: 400px;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:max-w-\[450px\] {
    max-width: 450px;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:bg-background {
    --tw-bg-opacity: 1;
    background-color: rgb(28 31 57 / var(--tw-bg-opacity));
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-\[image\:var\(--image-url\)\] {
    background-image: var(--image-url);
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-foreground {
    --tw-text-opacity: 1;
    color: rgb(190 190 198 / var(--tw-text-opacity));
  }

  .md\:text-foreground-100 {
    --tw-text-opacity: 1;
    color: rgb(242 242 244 / var(--tw-text-opacity));
  }

  .md\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }

  .md\:before\:h-\[425\]:before {
    content: var(--tw-content);
    height: 425px;
  }

  .md\:before\:w-\[425\]:before {
    content: var(--tw-content);
    width: 425px;
  }
}

@media (width >= 1024px) {
  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:right-\[-100px\] {
    right: -100px;
  }

  .lg\:top-\[-150px\] {
    top: -150px;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:min-h-\[420\] {
    min-height: 420px;
  }

  .lg\:min-h-\[600\] {
    min-height: 600px;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-\[330px\] {
    max-width: 330px;
  }

  .lg\:max-w-\[400px\] {
    max-width: 400px;
  }

  .lg\:max-w-\[440px\] {
    max-width: 440px;
  }

  .lg\:max-w-\[760px\] {
    max-width: 760px;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:translate-x-\[10\%\] {
    --tw-translate-x: 10%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }
}

@media (width >= 1280px) {
  .xl\:right-\[-150px\] {
    right: -150px;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:min-h-\[520\] {
    min-height: 520px;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-36 {
    gap: 9rem;
  }

  .xl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:leading-\[5rem\] {
    line-height: 5rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:hidden {
    display: none;
  }

  .\32 xl\:max-h-\[600px\] {
    max-height: 600px;
  }

  .\32 xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (height >= 800px) {
  .height-sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .height-sm\:pt-\[5vh\] {
    padding-top: 5vh;
  }
}

.\[\&\>\*\:not\(\:last-child\)\]\:my-2 > :not(:last-child) {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (width >= 768px) {
  .md\:\[\&\>\*\:not\(\:last-child\)\]\:my-4 > :not(:last-child) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.\[\&\>div\>div\>input\]\:py-3 > div > div > input {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>div\>div\>input\]\:placeholder-background-300 > div > div > input::placeholder, .\[\&\>div\>div\>textarea\]\:placeholder-background-300 > div > div > textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(119 121 136 / var(--tw-placeholder-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>div\>textarea\]\:lg\:h-56 > div > div > textarea {
    height: 14rem;
  }
}

.\[\&\>div\>div\]\:justify-between > div > div {
  justify-content: space-between;
}

.\[\&\>div\>label\]\:flex-shrink-0 > div > label {
  flex-shrink: 0;
}

.\[\&\>div\>label\]\:text-lg > div > label {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\[\&\>div\>label\]\:text-slate-900 > div > label {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>label\]\:lg\:w-1\/2 > div > label {
    width: 50%;
  }
}

.\[\&\>div\>p\]\:text-white > div > p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&\>div\]\:mx-auto > div {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>div\]\:max-w-5xl > div {
  max-width: 64rem;
}

.\[\&\>div\]\:items-center > div {
  align-items: center;
}

@media (width >= 768px) {
  .md\:\[\&\>div\]\:justify-start > div {
    justify-content: flex-start;
  }
}

@media (width >= 1024px) {
  .\[\&\>div\]\:lg\:flex > div {
    display: flex;
  }
}

@media (width >= 1280px) {
  .\[\&\>div\]\:xl\:gap-6 > div {
    gap: 1.5rem;
  }
}

.\[\&\>img\]\:duration-500 > img {
  transition-duration: .5s;
}

.\[\&\>p\:not\(\:last-child\)\]\:mb-6 > p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.\[\&\>p\>a\]\:text-accent > p > a {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.\[\&\>p\]\:mx-auto > p {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>p\]\:mb-4 > p {
  margin-bottom: 1rem;
}

.\[\&\>p\]\:mb-6 > p {
  margin-bottom: 1.5rem;
}

.\[\&\>p\]\:mt-0 > p {
  margin-top: 0;
}

.\[\&\>p\]\:max-w-xl > p {
  max-width: 36rem;
}

.\[\&\>p\]\:text-xl > p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  .lg\:\[\&\>p\]\:leading-8 > p {
    line-height: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:\[\&\>p\]\:leading-10 > p {
    line-height: 2.5rem;
  }
}

.\[\&\>span\]\:px-2 > span {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&\>span\]\:py-1 > span {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.\[\&\>ul\]\:text-xl > ul {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.wp-block-embed__wrapper {
  position: relative;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
  content: "";
  padding-top: 56.25%;
  display: block;
}

.wp-has-aspect-ratio iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.wp-block-embed iframe {
  max-width: 100%;
}

figure {
  margin: 0 0 1rem;
}

.wp-block-image img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: sweet-sans-pro;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

:root {
  --screen-width: 100vw;
  --current-screen: 100vw;
  --container-padding: 1rem;
}

@media (width >= 640px) {
  :root {
    --current-screen: 640px;
  }
}

@media (width >= 768px) {
  :root {
    --current-screen: 768px;
  }
}

@media (width >= 1024px) {
  :root {
    --current-screen: 1024px;
  }
}

@media (width >= 1280px) {
  :root {
    --current-screen: 1280px;
  }
}

@media (width >= 1536px) {
  :root {
    --current-screen: 1536px;
  }
}

@media (height >= 800px) {
  :root --current-screen {
    raw: (min-height: 800px);
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: #494c61;
  max-width: 60ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.dark .prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: 2.25rem;
  font-weight: 700;
  font-size: [object Object];
  letter-spacing: .05em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-family: tribute;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-size: .875rem;
  font-weight: 600;
  font-size: [object Object];
  text-transform: uppercase;
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-family: termina;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose :where(h1, h2, h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #6d4c3e;
  font-weight: 400;
}

.prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
}

.dark .prose :where(p > button):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: #d3b2a4;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.77778;
}

.prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.09091em;
  margin-bottom: 1.09091em;
  font-size: 1.22222em;
  line-height: 1.45455;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-left: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .833333em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.86667em;
  margin-bottom: 1.06667em;
  font-size: 1.66667em;
  line-height: 1.33333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  font-size: 1.33333em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: .444444em;
  line-height: 1.55556;
}

.prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .3125rem;
  padding: .222222em .444444em;
  font-size: .888889em;
}

.prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .866667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 1.5em;
  font-size: .888889em;
  line-height: 1.75;
}

.prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(.prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(.prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  padding-left: 1.55556em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3.11111em;
  margin-bottom: 3.11111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .75em;
  padding-left: .75em;
  padding-right: .75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(.prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

:where(.prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 2.25rem;
  font-size: [object Object];
  margin-top: 0;
  font-family: termina;
}

.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.prose-2xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.prose-2xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.prose-2xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-2xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.prose-2xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.prose-2xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.prose-2xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .375rem;
  padding: .25em .333333em;
  font-size: .833333em;
}

.prose-2xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
}

.prose-2xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-2xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-2xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.prose-2xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose-2xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(.prose-2xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-2xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.58333em;
}

.prose-2xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose-2xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-2xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.prose-2xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.prose-2xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .8em .6em;
}

.prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.prose-2xl :where(.prose-2xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(.prose-2xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-8 {
  inset: 2rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-left-0 {
  left: 0;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1\/2 {
  left: -50%;
}

.-left-24 {
  left: -6rem;
}

.-left-\[240px\] {
  left: -240px;
}

.-right-12 {
  right: -3rem;
}

.-right-\[15\%\] {
  right: -15%;
}

.-top-24 {
  top: -6rem;
}

.-top-32 {
  top: -8rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-52 {
  top: -13rem;
}

.-top-\[5\%\] {
  top: -5%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-32 {
  top: 8rem;
}

.top-\[-400px\] {
  top: -400px;
}

.top-\[45\%\] {
  top: 45%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[50\] {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[999999\] {
  z-index: 999999;
}

.z-\[9999\] {
  z-index: 9999;
}

.z-\[999\] {
  z-index: 999;
}

.z-\[99\] {
  z-index: 99;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-\[110\] {
  margin-top: -110px;
  margin-bottom: -110px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-\[5px\] {
  margin-top: -5px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-photo {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[25\] {
  height: 25px;
}

.h-\[300\] {
  height: 300px;
}

.h-\[345px\] {
  height: 345px;
}

.h-\[475px\] {
  height: 475px;
}

.h-\[90px\] {
  height: 90px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[175px\] {
  max-height: 175px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[600\] {
  max-height: 600px;
}

.max-h-\[650px\] {
  max-height: 650px;
}

.max-h-\[750px\] {
  max-height: 750px;
}

.min-h-1\/2screen {
  min-height: 50vh;
}

.min-h-\[1em\] {
  min-height: 1em;
}

.min-h-\[420\] {
  min-height: 420px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[25\] {
  width: 25px;
}

.w-\[400\] {
  width: 400px;
}

.w-\[46\%\] {
  width: 46%;
}

.w-\[475px\] {
  width: 475px;
}

.w-\[90px\] {
  width: 90px;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[115px\] {
  max-width: 115px;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[215px\] {
  max-width: 215px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[248px\] {
  max-width: 248px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[330px\] {
  max-width: 330px;
}

.max-w-\[540\] {
  max-width: 540px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[30\%\] {
  --tw-translate-y: -30%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[300\%\] {
  --tw-translate-y: -300%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-96 {
  --tw-translate-y: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[400px\] {
  --tw-translate-y: 400px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-270 {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.35\] {
  --tw-scale-x: .35;
  --tw-scale-y: .35;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[2\] {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[3\.0\] {
  --tw-scale-x: 3;
  --tw-scale-y: 3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-background-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(28 31 57 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

.place-self-center {
  place-self: center;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#7F80A9\]\/60 {
  border-color: #7f80a999;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.border-background-200 {
  --tw-border-opacity: 1;
  border-color: rgb(164 165 176 / var(--tw-border-opacity));
}

.border-background-400 {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: rgb(190 190 198 / var(--tw-border-opacity));
}

.border-foreground-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 232 / var(--tw-border-opacity));
}

.border-foreground-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 216 221 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#7F80A9\]\/60 {
  background-color: #7f80a999;
}

.bg-accent, .bg-accent-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.bg-background-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

.bg-background-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

.bg-background-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

.bg-background\/50 {
  background-color: #1c1f3980;
}

.bg-background\/80 {
  background-color: #1c1f39cc;
}

.bg-background\/90 {
  background-color: #1c1f39e6;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-cyan {
  --tw-bg-opacity: 1;
  background-color: rgb(82 179 175 / var(--tw-bg-opacity));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.bg-foreground-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 216 221 / var(--tw-bg-opacity));
}

.bg-foreground-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.bg-foreground-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(114 114 119 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-magenta {
  --tw-bg-opacity: 1;
  background-color: rgb(183 49 99 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(244 164 64 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[image\:var\(--image-url\)\] {
  background-image: var(--image-url);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-accent-600\/90 {
  --tw-gradient-from: #916653e6 var(--tw-gradient-from-position);
  --tw-gradient-to: #91665300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-background-600\/80 {
  --tw-gradient-from: #16192ecc var(--tw-gradient-from-position);
  --tw-gradient-to: #16192e00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-accent\/75 {
  --tw-gradient-to: #b57f68bf var(--tw-gradient-to-position);
}

.to-background-600\/40 {
  --tw-gradient-to: #16192e66 var(--tw-gradient-to-position);
}

.to-foreground-100 {
  --tw-gradient-to: #f2f2f4 var(--tw-gradient-to-position);
}

.bg-\[cover\] {
  background-size: cover;
}

.bg-\[length\:100vw_auto\] {
  background-size: 100vw;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-\[center_top_1rem\] {
  background-position: 50% 1rem;
}

.bg-\[center_top_2rem\] {
  background-position: 50% 2rem;
}

.bg-\[center_top_4rem\] {
  background-position: 50% 4rem;
}

.bg-bottom {
  background-position: bottom;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-accent {
  fill: #b57f68;
}

.fill-background {
  fill: #1c1f39;
}

.stroke-black {
  stroke: #000;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-left-top {
  object-position: left top;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-display {
  font-family: termina;
}

.font-mono {
  font-family: monospace;
}

.font-sans {
  font-family: sweet-sans-pro;
}

.font-serif {
  font-family: tribute;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.not-italic {
  font-style: normal;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-\[\.10rem\] {
  letter-spacing: .1rem;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-accent-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(181 127 104 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-100 {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.text-accent-500 {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.text-accent-700 {
  --tw-text-opacity: 1;
  color: rgb(109 76 62 / var(--tw-text-opacity));
}

.text-accent-800 {
  --tw-text-opacity: 1;
  color: rgb(72 51 42 / var(--tw-text-opacity));
}

.text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-400 {
  --tw-text-opacity: 1;
  color: rgb(73 76 97 / var(--tw-text-opacity));
}

.text-background-500 {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.text-background-900 {
  --tw-text-opacity: 1;
  color: rgb(6 6 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-cyan {
  --tw-text-opacity: 1;
  color: rgb(82 179 175 / var(--tw-text-opacity));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-100 {
  --tw-text-opacity: 1;
  color: rgb(242 242 244 / var(--tw-text-opacity));
}

.text-foreground-300 {
  --tw-text-opacity: 1;
  color: rgb(216 216 221 / var(--tw-text-opacity));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.text-foreground-500\/50 {
  color: #bebec680;
}

.text-foreground-700 {
  --tw-text-opacity: 1;
  color: rgb(114 114 119 / var(--tw-text-opacity));
}

.text-foreground-800 {
  --tw-text-opacity: 1;
  color: rgb(76 76 79 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-magenta {
  --tw-text-opacity: 1;
  color: rgb(183 49 99 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(244 164 64 / var(--tw-text-opacity));
}

.text-yellow\/50 {
  color: #f4a44080;
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-background-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(210 210 215 / var(--tw-placeholder-opacity));
}

.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(254 226 226 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-background-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 165 176 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-0 {
  transition-delay: 0s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-\[200ms\] {
  transition-delay: .2s;
}

.delay-\[400ms\] {
  transition-delay: .4s;
}

.delay-\[4s\] {
  transition-delay: 4s;
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-\[1s\] {
  transition-duration: 1s;
}

.duration-\[3s\] {
  transition-duration: 3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.fluid-base {
  font-size: 18px;
  line-height: var(--fluid-line-height);
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.4;
  --fluid-letter-spacing: .5px;
}

.fluid-lg {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .009375em;
  font-size: clamp(1.25rem, .9741vw + .9253rem, 1.618rem);
  line-height: var(--fluid-line-height);
}

.fluid-xl {
  letter-spacing: var(--fluid-letter-spacing);
  --fluid-line-height: 1.2;
  --fluid-letter-spacing: .0125em;
  font-size: clamp(1.5625rem, 2.7938vw + .6312rem, 2.6179rem);
  line-height: var(--fluid-line-height);
}

.block__content h2, .block__content h3 {
  text-wrap: balance;
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
  margin-bottom: 1.25rem;
}

.block__content h2 {
  text-transform: uppercase;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 1280px) {
  .block__content h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.block__content h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 1280px) {
  .block__content h3 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.block__content p {
  text-wrap: pretty;
}

.block__content p:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

.block__detailed-columns h2, .block__detailed-columns h3 {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
  margin-bottom: 1.25rem;
  font-weight: 400;
}

.block__detailed-columns h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  .block__detailed-columns h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.block__detailed-columns h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 768px) {
  .block__detailed-columns h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.block__detailed-columns ul {
  list-style-type: none;
}

.block__textimage h2, .block__textimage h3 {
  text-wrap: balance;
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
  margin-bottom: .75rem;
}

.block__textimage div.prose h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 1280px) {
  .block__textimage div.prose h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.block__textimage div.prose h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 1280px) {
  .block__textimage div.prose h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.block__textimage p {
  text-wrap: pretty;
}

.block__textimage p:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

.block__quotes .swiper button {
  background-color: #0000;
}

.block__quotes .swiper button svg {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.block__point-person p {
  text-wrap: pretty;
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

@media (width >= 1024px) {
  .lg\:container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (width >= 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }

  .lg\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .lg\:prose-lg :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .lg\:prose-lg :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .lg\:prose-lg :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .lg\:prose-lg :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .lg\:prose-lg :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .lg\:prose-lg :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .222222em .444444em;
    font-size: .888889em;
  }

  .lg\:prose-lg :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .lg\:prose-lg :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .lg\:prose-lg :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .lg\:prose-lg :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .lg\:prose-lg :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .lg\:prose-lg :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:prose-lg :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:prose-lg :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:prose-lg :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    padding-left: 1.55556em;
  }

  .lg\:prose-lg :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .lg\:prose-lg :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .lg\:prose-lg :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .lg\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .75em;
  }

  .lg\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-lg :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:prose-lg :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-lg :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-lg :where(.lg\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  :where(.lg\:prose-lg.intro h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 2.25rem;
    font-size: [object Object];
    margin-top: 0;
    font-family: termina;
  }
}

@media (width >= 1280px) {
  .xl\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .xl\:prose-xl :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .xl\:prose-xl :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.06667em;
  }

  .xl\:prose-xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .xl\:prose-xl :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .xl\:prose-xl :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.25rem;
    font-size: [object Object];
    margin-top: 1.6em;
    margin-bottom: .666667em;
    line-height: 1.33333;
  }

  .xl\:prose-xl :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .xl\:prose-xl :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .3125rem;
    padding: .25em .4em;
    font-size: .9em;
  }

  .xl\:prose-xl :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .xl\:prose-xl :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .xl\:prose-xl :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1.11111em 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .xl\:prose-xl :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .xl\:prose-xl :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .xl\:prose-xl :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .xl\:prose-xl :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .xl\:prose-xl :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    padding-left: 1.6em;
  }

  .xl\:prose-xl :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .xl\:prose-xl :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .xl\:prose-xl :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-bottom: .888889em;
    padding-left: .666667em;
    padding-right: .666667em;
  }

  .xl\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding: .888889em .666667em;
  }

  .xl\:prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-xl :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .xl\:prose-xl :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-xl :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-xl :where(.xl\:prose-xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-x-1\/2:before {
  content: var(--tw-content);
  left: 50%;
  right: 50%;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:h-3\/4:before {
  content: var(--tw-content);
  height: 75%;
}

.before\:h-\[2px\]:before {
  content: var(--tw-content);
  height: 2px;
}

.before\:h-\[425\]:before {
  content: var(--tw-content);
  height: 425px;
}

.before\:w-3\/4:before {
  content: var(--tw-content);
  width: 75%;
}

.before\:w-\[200px\]:before {
  content: var(--tw-content);
  width: 200px;
}

.before\:w-\[425\]:before {
  content: var(--tw-content);
  width: 425px;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-full:before {
  content: var(--tw-content);
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 198 / var(--tw-bg-opacity));
}

.before\:bg-\[image\:var\(--image-url\)\]:before {
  content: var(--tw-content);
  background-image: var(--image-url);
}

.before\:bg-contain:before {
  content: var(--tw-content);
  background-size: contain;
}

.before\:bg-no-repeat:before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:opacity-80:before {
  content: var(--tw-content);
  opacity: .8;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-accent:hover {
  --tw-border-opacity: 1;
  border-color: rgb(181 127 104 / var(--tw-border-opacity));
}

.hover\:bg-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.hover\:bg-accent-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(145 102 83 / var(--tw-bg-opacity));
}

.hover\:bg-background-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 210 215 / var(--tw-bg-opacity));
}

.hover\:bg-background-400\/50:hover {
  background-color: #494c6180;
}

.hover\:bg-foreground-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 232 / var(--tw-bg-opacity));
}

.hover\:text-accent:hover {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.hover\:text-accent-100:hover {
  --tw-text-opacity: 1;
  color: rgb(240 229 225 / var(--tw-text-opacity));
}

.hover\:text-accent-900:hover {
  --tw-text-opacity: 1;
  color: rgb(36 25 21 / var(--tw-text-opacity));
}

.hover\:text-background:hover {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.hover\:text-foreground-500:hover {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:ring-4:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-foreground\/80:hover {
  --tw-ring-color: #bebec6cc;
}

.hover\:ring-offset-4:hover {
  --tw-ring-offset-width: 4px;
}

.hover\:ring-offset-\[\#303156\]:hover {
  --tw-ring-offset-color: #303156;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-accent:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.focus\:ring-accent-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 204 195 / var(--tw-ring-opacity));
}

.focus\:ring-foreground:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-background:focus {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:bg-accent:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(181 127 104 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-200:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(164 165 176 / var(--tw-bg-opacity));
}

.active\:hover\:bg-background-400\/25:hover:active {
  background-color: #494c6140;
}

.active\:hover\:bg-foreground-100:hover:active {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 244 / var(--tw-bg-opacity));
}

.active\:hover\:ring-2:hover:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:hover\:ring-accent:hover:active, .active\:hover\:ring-accent-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(181 127 104 / var(--tw-ring-opacity));
}

.active\:hover\:ring-background-500:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 31 57 / var(--tw-ring-opacity));
}

.active\:hover\:ring-foreground:hover:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 190 198 / var(--tw-ring-opacity));
}

.active\:hover\:ring-offset-2:hover:active {
  --tw-ring-offset-width: 2px;
}

.active\:hover\:ring-offset-background:hover:active, .active\:hover\:ring-offset-background-500:hover:active {
  --tw-ring-offset-color: #1c1f39;
}

.active\:hover\:ring-offset-background-700:hover:active {
  --tw-ring-offset-color: #111322;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-25:disabled {
  opacity: .25;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group\/item:hover .group-hover\/item\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.group:hover .group-hover\:stroke-accent {
  stroke: #b57f68;
}

.group\/cs-link:hover .group-hover\/cs-link\:text-accent-500, .group\/item:hover .group-hover\/item\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:text-foreground {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-accent {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-background {
  --tw-text-opacity: 1;
  color: rgb(28 31 57 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/item:hover .group-hover\/item\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group:hover .group-hover\:mix-blend-normal {
  mix-blend-mode: normal;
}

.group:hover .group-hover\:grayscale-0 {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:border-background-400) {
  --tw-border-opacity: 1;
  border-color: rgb(73 76 97 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-background-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 19 34 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-background-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(11 12 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-background-100) {
  --tw-text-opacity: 1;
  color: rgb(210 210 215 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-foreground), :is(.dark .dark\:text-foreground-500) {
  --tw-text-opacity: 1;
  color: rgb(190 190 198 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-400) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-background-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(73 76 97 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:opacity-50) {
  opacity: .5;
}

:is(.dark .dark\:ring-background-400) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:ring-red-500) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:hover\:bg-background-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 25 46 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:opacity-75:hover) {
  opacity: .75;
}

:is(.dark .dark\:focus\:ring-accent-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(145 102 83 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-background-400:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(73 76 97 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-offset-background:focus) {
  --tw-ring-offset-color: #1c1f39;
}

:is(.dark .dark\:focus\:ring-offset-background-700:focus) {
  --tw-ring-offset-color: #111322;
}

:is(.dark .dark\:active\:opacity-100:active) {
  opacity: 1;
}

:is(.dark .dark\:active\:hover\:bg-background-500:hover:active) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 31 57 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm\:-top-8 {
    top: -2rem;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:-mt-0 {
    margin-top: 0;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:max-w-60 {
    max-width: 15rem;
  }

  .sm\:max-w-80 {
    max-width: 20rem;
  }

  .sm\:max-w-\[140px\] {
    max-width: 140px;
  }

  .sm\:max-w-\[155px\] {
    max-width: 155px;
  }

  .sm\:max-w-\[560px\] {
    max-width: 560px;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:tracking-\[\.18rem\] {
    letter-spacing: .18rem;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md\:-right-12 {
    right: -3rem;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:right-0, .md\:right-\[0\] {
    right: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[-250px\] {
    top: -250px;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-auto {
    aspect-ratio: auto;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:max-h-\[300px\] {
    max-height: 300px;
  }

  .md\:min-h-\[400\] {
    min-height: 400px;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:max-w-\[450px\] {
    max-width: 450px;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:bg-background {
    --tw-bg-opacity: 1;
    background-color: rgb(28 31 57 / var(--tw-bg-opacity));
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-\[image\:var\(--image-url\)\] {
    background-image: var(--image-url);
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-foreground {
    --tw-text-opacity: 1;
    color: rgb(190 190 198 / var(--tw-text-opacity));
  }

  .md\:text-foreground-100 {
    --tw-text-opacity: 1;
    color: rgb(242 242 244 / var(--tw-text-opacity));
  }

  .md\:before\:block:before {
    content: var(--tw-content);
    display: block;
  }

  .md\:before\:h-\[425\]:before {
    content: var(--tw-content);
    height: 425px;
  }

  .md\:before\:w-\[425\]:before {
    content: var(--tw-content);
    width: 425px;
  }
}

@media (width >= 1024px) {
  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:right-\[-100px\] {
    right: -100px;
  }

  .lg\:top-\[-150px\] {
    top: -150px;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:min-h-\[420\] {
    min-height: 420px;
  }

  .lg\:min-h-\[600\] {
    min-height: 600px;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-\[330px\] {
    max-width: 330px;
  }

  .lg\:max-w-\[400px\] {
    max-width: 400px;
  }

  .lg\:max-w-\[440px\] {
    max-width: 440px;
  }

  .lg\:max-w-\[760px\] {
    max-width: 760px;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:translate-x-\[10\%\] {
    --tw-translate-x: 10%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:scale-\[2\.0\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }
}

@media (width >= 1280px) {
  .xl\:right-\[-150px\] {
    right: -150px;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:min-h-\[520\] {
    min-height: 520px;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-36 {
    gap: 9rem;
  }

  .xl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:leading-\[5rem\] {
    line-height: 5rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:hidden {
    display: none;
  }

  .\32 xl\:max-h-\[600px\] {
    max-height: 600px;
  }

  .\32 xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (height >= 800px) {
  .height-sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .height-sm\:pt-\[5vh\] {
    padding-top: 5vh;
  }
}

.\[\&\>\*\:not\(\:last-child\)\]\:my-2 > :not(:last-child) {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (width >= 768px) {
  .md\:\[\&\>\*\:not\(\:last-child\)\]\:my-4 > :not(:last-child) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.\[\&\>div\>div\>input\]\:py-3 > div > div > input {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>div\>div\>input\]\:placeholder-background-300 > div > div > input::placeholder, .\[\&\>div\>div\>textarea\]\:placeholder-background-300 > div > div > textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(119 121 136 / var(--tw-placeholder-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>div\>textarea\]\:lg\:h-56 > div > div > textarea {
    height: 14rem;
  }
}

.\[\&\>div\>div\]\:justify-between > div > div {
  justify-content: space-between;
}

.\[\&\>div\>label\]\:flex-shrink-0 > div > label {
  flex-shrink: 0;
}

.\[\&\>div\>label\]\:text-lg > div > label {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\[\&\>div\>label\]\:text-slate-900 > div > label {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

@media (width >= 1024px) {
  .\[\&\>div\>label\]\:lg\:w-1\/2 > div > label {
    width: 50%;
  }
}

.\[\&\>div\>p\]\:text-white > div > p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&\>div\]\:mx-auto > div {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>div\]\:max-w-5xl > div {
  max-width: 64rem;
}

.\[\&\>div\]\:items-center > div {
  align-items: center;
}

@media (width >= 768px) {
  .md\:\[\&\>div\]\:justify-start > div {
    justify-content: flex-start;
  }
}

@media (width >= 1024px) {
  .\[\&\>div\]\:lg\:flex > div {
    display: flex;
  }
}

@media (width >= 1280px) {
  .\[\&\>div\]\:xl\:gap-6 > div {
    gap: 1.5rem;
  }
}

.\[\&\>img\]\:duration-500 > img {
  transition-duration: .5s;
}

.\[\&\>p\:not\(\:last-child\)\]\:mb-6 > p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.\[\&\>p\>a\]\:text-accent > p > a {
  --tw-text-opacity: 1;
  color: rgb(181 127 104 / var(--tw-text-opacity));
}

.\[\&\>p\]\:mx-auto > p {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>p\]\:mb-4 > p {
  margin-bottom: 1rem;
}

.\[\&\>p\]\:mb-6 > p {
  margin-bottom: 1.5rem;
}

.\[\&\>p\]\:mt-0 > p {
  margin-top: 0;
}

.\[\&\>p\]\:max-w-xl > p {
  max-width: 36rem;
}

.\[\&\>p\]\:text-xl > p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  .lg\:\[\&\>p\]\:leading-8 > p {
    line-height: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:\[\&\>p\]\:leading-10 > p {
    line-height: 2.5rem;
  }
}

.\[\&\>span\]\:px-2 > span {
  padding-left: .5rem;
  padding-right: .5rem;
}

.\[\&\>span\]\:py-1 > span {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.\[\&\>ul\]\:text-xl > ul {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

/*# sourceMappingURL=index.10bdde81.css.map */
